<template>
  <form-kit
    component-name="input-keyword"
    type="text"
    name="keyword"
    :label="label || undefined"
    :placeholder="placeholder || undefined"
    validation="required"
    :label-class="size === 'huge' ? '!text-body-big' : undefined"
    :input-class="size === 'huge' ? '!text-heading-1/none !pb-lg' : undefined"
    suffix-icon="search"
    :suffix-icon-class="`string text ${
      size === 'huge' ? 'w-12 pb-2xl' : 'w-4 pb-sm'
    } h-auto border-b border-current formkit-invalid:text-skin-danger formkit-invalid:border-skin-danger`"
    inner-class="flex items-end"
    :help-class="size === 'huge' ? '!text-body-big' : undefined"
    :help="help || undefined"
  />
</template>

<script setup>
const props = defineProps({
  size: {
    type: String,
    required: false,
    validator: function (value) {
      return ["regular", "huge"].indexOf(value) !== -1;
    },
    default: "regular",
  },
  help: {
    type: String,
    required: false,
  },
  label: {
    type: String,
    required: false,
  },
  placeholder: {
    type: String,
    required: false,
  },
});
</script>
